export const languages = [
  { name: 'English', code: 'en' },
  { name: 'Finnish', code: 'fi' },
  { name: 'Swedish', code: 'sv' }
]

const translate = (key, lang = 'sv') => {
  if (!translations[lang]) return 'language key missing'
  if (!translations[lang][key]) return 'translation key missing'
  return translations[lang][key]
}

const translations = {
  fi: {
    button_start_playing: 'SIIRRY PELAAMAAN',
    button_create_account: 'AVAA TILI',
    button_create_account_alt: 'LUO TILI',
    button_read_review: 'Lue Arvostelu',
    button_show_more_casinos: 'Näytä Lisää Kasinoita',
    button_go_to_casino: 'Siirry Kasinoon',
    title_search_options: 'Hakuvaihtoehdot',
    title_casino_filler_words: 'arvostelu | bonukset, ilmaiskierrokset ja kokemuksia',
    phrase_bonuses: 'Bonukset',
    phrase_bonuscode: 'Bonuskoodi',
    phrase_wagering_requirements: 'Kierrätysvaatimukset',
    phrase_welcome_bonus: 'Tervetuliaisbonus',
    phrase_vip_program: 'VIP-ohjelma',
    phrase_other_bonuses: 'Muut bonukset',
    phrase_review: 'Arvostelu',
    phrase_customer_support: 'Asiakaspalvelu',
    phrase_game_selection: 'Pelivalikoima',
    phrase_mobile_friendlyness: 'Mobiiliystävällisyys',
    phrase_usability: 'Käytettävyys',
    phrase_money_transfers: 'Rahansiirrot',
    phrase_summary: 'KOKONAISARVOSANA',
    phrase_game_providers: 'Pelivalmistajat',
    phrase_game_provider: 'Pelivalmistaja',
    phrase_licenses: 'Lisenssit',
    phrase_languages: 'Kielet',
    phrase_graphics: 'Grafiikat',
    phrase_theme: 'Teema',
    phrase_play_for_free:'Pelaa Ilmaiseksi',
    phrase_try: 'Kokeile',
    phrase_game_for_free: 'peliä ilmaiseksi',
    phrase_excitement: 'Jännitys',
    phrase_winning_possibilities:'Voittomahdollisuudet',
    phrase_currencies: 'Valuutat',
    phrase_payment_methods: 'Maksutavat',
    phrase_features: 'Ominaisuudet',
    phrase_mobile_version: 'Mobiiliversio',
    phrase_reels: 'Kelat',
    phrase_paylines: 'Maksulinjat',
    phrase_game_type:'Pelityyppi',
    phrase_read_more: 'Lue Lisää',
    form_label_name: 'Nimi',
    form_label_email:'Sähköposti Osoite',
    form_label_message: 'Viesti',
    form_button_send: 'Lähetä',
    button_read_review: 'Pelaa / Lue Arvostelu',
    
  },
  sv: {
    button_start_playing: 'SIIRRY PELAAMAAN',
    button_create_account: 'AVAA TILI',
    button_create_account_alt: 'LUO TILI',
    button_read_review: 'Lue Arvostelu',
    button_show_more_casinos: 'Näytä Lisää Kasinoita',
    button_go_to_casino: 'Siirry Kasinoon',
    title_search_options: 'Hakuvaihtoehdot',
    title_casino_filler_words: 'arvostelu | bonukset, ilmaiskierrokset ja kokemuksia',
    phrase_bonuses: 'Bonukset',
    phrase_bonuscode: 'Bonuskoodi',
    phrase_wagering_requirements: 'Kierrätysvaatimukset',
    phrase_welcome_bonus: 'Tervetuliaisbonus',
    phrase_vip_program: 'VIP-ohjelma',
    phrase_other_bonuses: 'Muut bonukset',
    phrase_review: 'Arvostelu',
    phrase_customer_support: 'Asiakaspalvelu',
    phrase_game_selection: 'Pelivalikoima',
    phrase_mobile_friendlyness: 'Mobiiliystävällisyys',
    phrase_usability: 'Käytettävyys',
    phrase_money_transfers: 'Rahansiirrot',
    phrase_summary: 'KOKONAISARVOSANA',
    phrase_game_providers: 'Pelivalmistajat',
    phrase_game_provider: 'Pelivalmistaja',
    phrase_licenses: 'Lisenssit',
    phrase_languages: 'Kielet',
    phrase_graphics: 'Grafiikat',
    phrase_theme: 'Teema',
    phrase_play_for_free:'Pelaa Ilmaiseksi',
    phrase_try: 'Kokeile',
    phrase_game_for_free: 'peliä ilmaiseksi',
    phrase_excitement: 'Jännitys',
    phrase_winning_possibilities:'Voittomahdollisuudet',
    phrase_currencies: 'Valuutat',
    phrase_payment_methods: 'Maksutavat',
    phrase_features: 'Ominaisuudet',
    phrase_mobile_version: 'Mobiiliversio',
    phrase_reels: 'Kelat',
    phrase_paylines: 'Maksulinjat',
    phrase_game_type:'Pelityyppi',
    phrase_read_more: 'LÄS ARTIKEL',
    form_label_name: 'Namn',
    form_label_email:'E-post',
    form_label_message: 'Meddelande',
    form_button_send: 'Skicka',
    button_read_review: 'Spela',
    
  }
}

export default translate
