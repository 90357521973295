import React from "react"
import "./scss/index.scss"
import NewsGridItem from "./NewsGridItem"

function Grid({items}) {
  return (
    <div className="news-grid">
      {items &&
        items.map((item, i) => {
          return <NewsGridItem key={i} item={item} />
        })}
    </div>
  )
}

export default Grid

