import React from "react"
import { Link } from "gatsby"
import translate from '../../i18n'

function GridItem({ item }) {
  return (
    <div className="item">
      <img className='item__featured-image' src={item.image} alt={item.title} />
      <h3 className="item__title">{item.title}</h3>
      <div className="item__excerpt">
        {item.excerpt}
      </div>
      <div className="item__actions">
        <Link to={item.url}>{translate('phrase_read_more')}</Link>
      </div>
    </div>
  )
}

export default GridItem
