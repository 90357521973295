import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/main'
import HeaderMeta from '../components/HeaderMeta'
import ProgressiveBgImage from '../components/ProgressiveBgImage'
import LoanGrid from '../components/LoanGrid'
import NewsGrid from '../components/NewsGrid'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    
    const siteTitle = data.site.siteMetadata.title
    const articles = data.articles.edges
    const lenders = data.lenders.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <HeaderMeta
          title="Ta kontroll över din privatekonomi men hjälp av Bluemonday.se"
          description="Luotettavat nettikasinot, ajankohtaiset peliarvostelut, bonukset ja yleistä tietoutta nettikasiBehöver du låna pengar och vill hitta det bästa lånet? Med våra smarta tips kan du hitta förmånliga lån som passar just din personliga ekonomi"
        />
        <ProgressiveBgImage
          hqImage="/images/office-desk.jpg"
          lqImage="/images/office-desk-lq.jpg"
        >
          <section className="section content-box transparent">
            <div className="hero-header-box">
              <h1>
                Låna pengar till investeringar eller spara för en mer hållbar
                ekonomi?
              </h1>
              <p>
                Din guide till en bättre privatekonomi och mer personlig frihet
              </p>
            </div>
          </section>
          <section className="section content-box">
            <h2>Läs våra tips om hur du lånar smart och sparar pengar</h2>
            <p>
              Då man har bestämt sig för att låna pengar, oavsett om det är
              frågan om ett mindre snabblån eller ett större lån som exempelvis
              bostadslån, billån eller lån för oväntade utgifter så är det bra
              att ta reda på alla villkor, räntor, regler och totala belopp för
              lånen. Det är även avgörande att hitta de bästa bankerna och
              långivaren med de förmånligaste lånen, mest fördelaktiga räntorna
              och rimligaste återbetalnings villkoren. Med hjälp av vår guide
              hittar du information som hjälper dig att hitta de bästa lånen som
              passar just dig!
            </p>

            <h2>Bör man låna pengar istället för att spara?</h2>
            <p>
              Talesättet “har man guld så får man guld” är tänkvärt, och den som
              sparar kanske funderar på hur de här pengarna kan bli större på
              kortare tid. Det kan låta lite märkligt men man kan faktiskt tjäna
              mer på att ta ett lån och använda den budget man har för att spara
              till att betala av på lånet. Med att låna pengar kan man nämligen
              investera i en bostad eller i något annat som kan få pengarna att
              växa. Det här blir då onekligen ett bättre alternativ än att samla
              pengarna på banken där de inte blir särskilt mycket större ens
              efter många år. Om man väljer att ta ett lån istället för att
              spara så måste man dock vara helt på det klara med vad man gör.
              Ekonomisk rådgivning kan vara ett måste och man ska inte investera
              sina besparingar i osäkra projekt.
            </p>
          </section>

          <section className="section content-box">
            <h2>Investera i aktier eller råvaror</h2>
            <p>
              Om man vill investera i aktier eller råvaror så kan man inte låna
              pengar direkt för detta. Visst, ett privatlån kan bli ganska stort
              och här behöver man ingen säkerhet men man kan inte säga att det
              här är riskfritt. För investeringar av den här typen så bör man
              hellre jobba med ett startkapital som man har sparat ihop och inte
              riskera att förlora pengar som man dessutom måste betala ränta på.
            </p>
            <h2>Rådgör med en expert</h2>
            <p>
              Innan du lånar pengar för att tjäna pengar så ska du rådgöra med
              en ekonomisk expert. Det här är verkligen viktigt och du kan finna
              den här typen av rådgivning via din kommun eller bank. En del
              kreditgivare ger även rådgivningen som en gratis tjänst men tänk
              på att deras syfte med största sannolikhet är att du ska bli kund
              och ta det där lånet.
            </p>
            <h2>Vilken typ av lån skall man ansöka?</h2>
            <p>
              Det finns en mängd olika typer av lån och den bästa riktlinjen för
              vilken typ av lån man skall ansöka om egentligen att klargöra
              vilket syfte man har med lånet. Några av de vanligaste lånen är
              privatlån, bostadslån, billån och snabblån. Det viktigaste är att
              först och främst fastställa hur mycket man behöver låna och vilket
              är syftet med lånet och sedan jämföra räntor och villkor för att
              få det bästa möjliga lånet.
            </p>
            <h2>Då du behöver ansöka om lån</h2>
            <p>
              Det finns saker att tänka på då man inser att det har blivit dags
              för ett större lån, som till exempel ett huslån. Innan du ens hör
              av dig till en bank eller annan kreditgivare så ska du tänka
              igenom hur många lån du har just nu. Du kanske tror att du bara
              har ett billån men om du även har köpt saker på kredit så kommer
              detta att synas i din kreditupplysning som ansökningar om lån. Med
              tanke på detta så ska du noga tänka igenom dina handlingar en bra
              tid innan du vänder dig till en lånegivare så att du inte har
              onödiga poster i kreditupplysningen som kan komma att leda till
              ett nej till lånet.
            </p>

            <h2>Investera i boende</h2>
            <p>
              För många så är det så att det kostar mer att hyra än vad det hade
              gjort att bo i en egen bostad. Nu är förstås frågan var gränsen
              går och man måste ta en titt på hur mycket ett lån kommer att
              kosta per månad och lägga ihop detta med månadskostnaden som man
              kommer att ha för sitt boende. Även om detta kommer att bli mer än
              hyran så betyder det inte att lånet inte är värt att ta. Kom ihåg
              att du betalar tillbaka pengar som till sist blir dina i och med
              att bostaden är din. När du säljer boendet så kommer du
              förhoppningsvis att få igen de pengar som du lånat, så det här är
              ju också en form av sparande. När man hyr så kastar man ju
              pengarna i sjön för man har ju ingen ekonomisk behållning av
              bostaden då man inte äger den.
            </p>
            <h2>Låna pengar för att köpa bostad utomlands</h2>
            <p>
              Man kan också låna pengar för att köpa en bostad utomlands som man
              sedan kan hyra ut. Det krävs dock en speciell typ av långivare
              eller bank till att få låna pengar för att köpa bostads utomlands.
              Eftersom själva bostaden man köper inte finns i Sverige och därmed
              inte kan läggas som pant till banken så krävs oftast att man har
              sparat ihop en rejäl handpenning för att få igenom låneansökan för
              att köpa bostaden utomlands.
            </p>
            <p>
              Ibland kan det bästa valet vara att låna pengar till bostaden i
              det specifika landet som du väljer att köpa bostaden i. Det
              förmånligaste bostadslånen för att exempelvis köpa lägenhet i
              Spanien eller övriga Europa ansöks i den lokala banken i
              respektive land. På det sättet kan handpenningen hållas ner till
              ca 20 procent och bostadsräntan blir förmånligare.
            </p>
            <p>
              Att köpa bostad utomlands är en fantastisk investering, inte nog
              med att man på så vis investerar i ägande av bostad, man får ju
              även en inkomst genom hyran. För att göra detta så måste man ha
              hjälp på plats av en duktig mäklare så att man inte blir lurad.
              Man kan antingen köpa en bostad för kortidsuthyrning som man även
              själv kan använda då och då eller så kan man investera i den typ
              av bostad som kommer att hyras ut under längre perioder.
            </p>
          </section>

          <LoanGrid
            items={lenders.map(({ node }) => {
              return {
                title: node.frontmatter.title || node.fields.slug,
                slug: node.fields.slug,
                image: node.frontmatter.logo,
                rating: node.frontmatter.rating,
                bullet_points: node.frontmatter.bullet_points,
                affiliate_link: node.frontmatter.affiliate_link,
                rating: node.frontmatter.rating,
                age_limit: node.frontmatter.age_limit,
                max_loan_amount: node.frontmatter.max_loan_amount,
                min_loan_amount: node.frontmatter.min_loan_amount,
                min_loan_period: node.frontmatter.min_loan_period,
                max_loan_period: node.frontmatter.max_loan_period,
                is_featured: node.frontmatter.is_featured,
                lender_type: node.frontmatter.lender_type
              }
            })}
          />

          <section className="section content-box dark">
            <h2>Nyheter</h2>
          </section>
          <section className="section">
            <NewsGrid
              items={articles.map(({ node }) => ({
                title: node.frontmatter.title || node.fields.slug,
                url: node.fields.slug,
                excerpt: node.frontmatter.excerpt,
                image: node.frontmatter.featured_image,
                logo: node.frontmatter.logo
              }))}
            />
          </section>
        </ProgressiveBgImage>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    articles: allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "article" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            description
            featured_image
            content_type
          }
        }
      }
    }

    lenders: allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "lender" } } }
      sort: { fields: [frontmatter___rating], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            slug
            title
            description
            featured_image
            logo
            content_type
            is_high_interest_loan
            affiliate_link
            rating
            age_limit
            max_loan_amount
            min_loan_amount
            min_loan_period
            max_loan_period
            affiliate_link
            is_featured
            lender_type
          }
        }
      }
    }
  }
`
